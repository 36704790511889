$(document).ready(function () {
  ("use strict");

  // ajust alçada mapes
  // $(window).resize(function () {
  //   var h = $('.centres-container').outerHeight();
  //   $('.map').height(h);
  // }).resize();

  // ------
  // slider
  // ------
  //$(".slider").slick();
  $(".home-slider").slick({
    dots: true,
  });

  // -----------
  // Collapsible (https://www.w3schools.com/howto/howto_js_collapsible.asp)
  // -----------
  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  }


  // -----------------
  // Smooth scroll top
  // -----------------


  if (window.matchMedia('(min-width: 64em)').matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".scrolltop").fadeIn();
      } else {
          $(".scrolltop").fadeOut();
      }
    });
    // var headerHeight = 82; // fixed header height
    $(function() {
      $('a[name]').addClass('anchor_offset');
      /*en cas de menú sticky, alçada àncores. Afegir css:
        .anchor_offset:before {
          content: " ";
          display: block;
          height: 120px; en funció l'alçada del menú sticky
          margin-top: -120px; en funció l'alçada del menú sticky
          visibility: hidden;
          }*/

    var hash = window.location.hash;
    if (hash != '') {
        var target = $(hash);
        target = target.length ? target : $('[name=' + hash.slice(1) +']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000);
            return false;
        }
    }

      $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);

          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              // if (window.matchMedia("(min-width: 64.0625em)").matches) {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top - headerHeight
              //       }, 1000);
              // } else {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top
              //       }, 1000);
              // }
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000);
            return false;
          }
        }
      });
    });
  }

  // Comentat per no posar hamburger a EV Medical
  // Menú: afegim botó i ocultem menú quan hi ha js
  // $("#menu")
  //   .before(
  //     '<button class="hamburger hamburger--squeeze" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="vh">Menú</span></button>'
  //   )
  //   .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  // var toggleMenu = document.querySelector(".navigation button");
  // var menu = document.querySelector("#menu");

  // toggleMenu.addEventListener("click", function () {
  //   var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
  //   toggleMenu.setAttribute("aria-expanded", !open);
  //   menu.hidden = !menu.hidden;
  // });

  // $(".hamburger").on("click", function () {
  //   // afegir classe al botó
  //   $(this).toggleClass("is-active");
  //   // afegir classe al body
  //   // $("body").toggleClass("js-menu-open");
  //   // afegir classe al header
  //   $(".header-bg").toggleClass("no-bg");
  // });
});
